@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;0,800;0,900;1,400;1,500&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.signUp-box {
  max-width: 720px;
}
.main-card {
  padding: 40px;
  -webkit-box-shadow: 0 15px 30px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 20%);
  background: #fff;
  border-radius: 8px;
}
.login-card .btn a {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}
.btn.btn-google,
.btn.btn-google:hover {
  background: #ea4335;
}
.btn.btn-facebook,
.btn.btn-facebook:hover {
  background: #3b5998;
  margin-top: 0;
}
.btn.btn-facebook:hover,
.btn.btn-google:hover {
  opacity: 0.9;
}

.login-page {
  background-color: #f6f7fc;
}
.login-page > div > .wrapper {
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}
.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
  height: 50vh;
  padding: 10vmax;
}
.login-box-msg {
  padding: 30px 0;
  font-size: 18px;
  color: #212529;
}
.login-title {
  text-align: center;
  padding: 0;
  margin: 0;
  font-size: 1.8rem;
  color: #060674;
}
.login-box-note {
  text-align: center;
  padding: 30px 0 0;
  margin: 0;
  color: #999999;
}
.login-logo {
  font-weight: 500;
  color: rgb(6, 6, 116);
}
.loginbtn {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
  max-width: 320px;
  font-weight: 600 !important;
}
.btn-email {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
  font-weight: 600 !important;
}
.checkbtn {
  font-size: 3vmax;
  display: flex !important;
  padding: 30px 0 0;
  justify-content: center;
  margin: auto;
  color: green;
}
.crossbtn {
  color: red;
}
.form-signin p.error {
  margin-bottom: 0;
  font-size: 0.8rem;
  font-weight: normal;
  color: red;
}
.form-signin .form-label {
  color: #212529;
  font-weight: normal !important;
}
p.signup-box-msg {
  margin: 0 0 30px;
  font-weight: 500;
  font-size: 1.2rem;
}
.profile-img {
  margin-top: 5px;
}
.login-with-text {
  text-align: center;
  margin: 1rem;
}
.login-with-button {
  display: flex;
  gap: 10px;
}
.login-with-button .btn-block {
  min-width: 50%;
}
.login-with-button .btn a {
  white-space: nowrap;
}
.cellAction {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.dataTables_filter label{
  font-weight: normal !important;
  white-space: nowrap;
  text-align: left;
}
.dataTables_filter input{
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}